import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// FPT-HoiLT2: [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development 
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { ErrorSkuCustomerComponent } from './components/pages/sku-customer/error/error.component';
import { MaintainSkuCustomerComponent } from './components/pages/sku-customer/maintain/maintain.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AnalyticsTable } from './components/pages/analytics-table/analytics-table.component';
import { AnalyticsCategoryPackageTable } from './components/pages/analytics-ctg-pkg-table/analytics-ctg-pkg-table.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IntageDashboardComponent } from './components/pages/intage/dashboard/intage_dashboard.component';
import { IntageVMDashboardComponent } from './components/pages/intage-vm/vm-dashboard/intage_vm_dashboard.component';
import { IntageFlashComponent } from './components/pages/intage/flash/intage_flash.component';
import { IntageFlashVMComponent } from './components/pages/intage-vm/flash/intage_flash_vm.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SummaryDashboardComponent } from './components/pages/dashboard/dashboard/summary-dashboard/summary-dashboard.component';
import { NsrTrendComponent } from './components/pages/dashboard/dashboard/nsr-trend/nsr-trend.component';
import { NsrDailyComponent } from './components/pages/dashboard/dashboard/nsr-daily/nsr-daily.component';
import { NsrMonthlyComponent } from './components/pages/dashboard/dashboard/nsr-monthly/nsr-monthly.component';
import { GPMonthlyComponent } from './components/pages/dashboard/dashboard/gp-monthly/gp-monthly.component';
import { SalePerformanceComponent } from './components/pages/dashboard/dashboard/sale-performance/sale-performance.component';
import { ScoreCardsComponent } from './components/charts/intage/score-cards/score_cards.component';
import { ScoreCardsVMComponent } from './components/charts/intage-vm/score-cards-vm/score_cards_vm.component';
import { IntageValueShareTimelineVMComponent } from './components/charts/intage-vm/value-share-timeline-vm/value-share-timeline-vm.component';
import { IntageOtcValueVsLyComponent } from './components/charts/intage/otc-value-vs-ly/otc-value-vs-ly.component';
import { IntageVMOtcValueVsLyComponent } from './components/charts/intage-vm/otc-value-vs-ly-vm/otc-value-vs-ly-vm.component';
import { IntageValueShareTimelineComponent } from './components/charts/intage/value-share-timeline/value-share-timeline.component';
import { IntageShareVsLyComponent } from './components/charts/intage/share-vs-ly/share-vs-ly.component';
import { IntageVMShareVsLyComponent } from './components/charts/intage-vm/share-vs-ly-vm/share-vs-ly-vm.component';
import { IntageSankeySummaryComponent } from './components/charts/intage/sankey-summary/sankey-summary.component';
import { IntageVMSankeySummaryComponent } from './components/charts/intage-vm/sankey-summary-vm/sankey-summary-vm.component';
import { IntageOtcMarketSizeComponent } from './components/charts/intage/otc-market-size/otc-market-size.component';
import { IntageOtcMarketShareByPackageComponent } from './components/charts/intage/otc-market-share-by-package/otc-market-share-by-package.component';
import { IntageOtcMarketShareByRegionComponent } from './components/charts/intage/otc-market-share-by-region/otc-market-share-by-region.component';
import { UnauthorizedComponent } from './components/pages/Unauthorized/unauthorize.component';
import { IntageOtcMarketShareVolumeCCBJIComponent } from './components/charts/intage/otc-market-share-volume-ccbji/otc-market-share-volume-ccbji.component';
import { IntageOtcMarketSizeVMCCBJIComponent } from './components/charts/intage-vm/otc-market-size-vm-ccbji/otc-market-size-vm-ccbji.component';
import { IntageOtcMarketShareVMByRegionComponent } from './components/charts/intage-vm/otc-market-share-vm-by-region/otc-market-share-vm-by-region.component';
import { HQProgressComponent } from './components/pages/hq-progress/hq_progress.component';
import { HQLevel1Component } from './components/charts/hq-progress/hq-level1/hq-level1.component';
import { HQLevel2Component } from './components/charts/hq-progress/hq-level2/hq-level2.component';
import { ProgressTimelineComponent } from './components/charts/hq-progress/hq-progress-timeline/hq-progress-timeline.component';
import { HQNationalLocalComponent } from './components/charts/hq-progress/hq-national-local/hq-national-local.component';

import { DistrictRouterProgressComponent } from './components/pages/district-route-progress/district_route_progress.component';
import { ProgressByDistrictComponent } from './components/charts/district-route-progress/progress-by-district/progress-by-district.component';
import { ProgressByRouteComponent } from './components/charts/district-route-progress/progress-by-route/progress-by-route.component';
import { DistrictTimelineComponent } from './components/charts/district-route-progress/district-timeline/district-timeline.component';
import { VsLyCategoryComponent } from './components/charts/sku-deepdive/vs-ly-category/vs-ly-category.component';
import { VsLyPackageComponent } from './components/charts/sku-deepdive/vs-ly-package/vs-ly-package.component';
import { VsLySKUComponent } from './components/charts/sku-deepdive/vs-ly-sku/vs-ly-sku.component';
import { VsLyTimelineComponent } from './components/charts/sku-deepdive/vs-ly-timeline/vs-ly-timeline.component';
import { VsLyAreaComponent } from './components/charts/area-deepdive/vs-ly-area/vs-ly-area.component';
import { VsLyChannelComponent } from './components/charts/area-deepdive/vs-ly-channel/vs-ly-channel.component';
import { VsLyRouteComponent } from './components/charts/area-deepdive/vs-ly-route/vs-ly-route.component';
import { VsLyDealerComponent } from './components/charts/area-deepdive/vs-ly-dealer/vs-ly-dealer.component';
import { VsLyAreaTimelineComponent } from './components/charts/area-deepdive/vs-ly-timeline/vs-ly-timeline.component';
import { AddMoreFilter } from './components/elements/add-more-filter/filters';
import { AddMoreFilterHQ } from './components/elements/add-more-filter-hq/filters-hq';
import { PhotoReportFilter } from './components/elements/photo-report-filter/photo-report-filter.component';

import { ProgressRankingComponent } from './components/pages/progress-ranking/progress-ranking.component';
import { AreaSalesRankingComponent } from './components/charts/progress-ranking/area-sales-ranking/area-sales-ranking.component';
import { FaceShareComponent } from './components/pages/faceshare/faceshare.component';
import { FaceShareByMarkerCategoryComponent } from './components/charts/faceshare/by-marker-category/by-marker-category.component';
import { FaceShareByAccountComponent } from './components/charts/faceshare/by-account/by-account.component';
import { FaceShareBySkuComponent } from './components/charts/faceshare/by-sku/by-sku.component';
import { FaceShareByAccountBySkuComponent } from './components/charts/faceshare/by-account-by-sku/by-account-by-sku';
import { PocComponent } from './components/pages/poc/poc.component';
import { PocByAccountComponent } from './components/charts/poc/by-account/by-account.component';
import { PocByCategoryComponent } from './components/charts/poc/by-category/by-category.component';

// FPT-HoiLT2: [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development
import { SkuCustomerComponent } from './components/pages/sku-customer/sku-customer.component';
import { SkuComponent } from './components/pages/sku-customer/sku/sku.component';
import { SkuDeepdiveComponent } from './components/pages/sku-deepdive/sku-deepdive.component';
import { AreaDeepdiveComponent } from './components/pages/area-deepdive/area-deepdive.component';
import { CustomerComponent } from './components/pages/sku-customer/customer/customer.component';
import { ScoreCardsFlashComponent } from './components/charts/intage/score-cards-flash/score_cards_flash.component';
import { IntageValueShareSummaryFlashComponent } from './components/charts/intage/value-share-summary-flash/value-share-summary-flash.component';
import { ProcessingComponent } from './components/pages/processing/processing.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CanvasComponent } from './components/elements/canvas/canvas.component';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';

import { MentionModule } from 'angular-mentions';
import { FilterRootsPipe } from './helpers/filtered-roots.pipe';
import { SortPipe } from './helpers/sort.pipe';
import { SESSION_STORAGE_KEYS } from './model/constants';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const url          = window.location.pathname;
  const ignoredPaths = ['/', '/dashboard', '/authentication', '/login', '/authentication/login', '/processing', '/unauthorized'];

  if (url && !ignoredPaths.includes(url)) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS?.REDIRECT_URL, url);
  }

  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['openid','email','profile']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid','email','profile'],
      prompt: 'select_account'
    },
  };
}

// You can find all the icons here: https://ionic.io/ionicons/
import { NgIconsModule } from '@ng-icons/core';
import { 
  IonCalendarClearOutline,
  IonDownloadOutline,
  IonGrid,
  IonDocumentTextOutline,
  IonDocumentOutline,
  IonTimeOutline,
  IonChatbubbleEllipsesOutline,
  IonArrowRedoOutline,
  IonEllipsisHorizontalOutline,
  IonPaperPlaneSharp,
  IonTrashOutline,
  IonCreateOutline,
  IonCloseSharp,
 } from '@ng-icons/ionicons';
 
import { DownloadButtonComponent } from './components/elements/download-button/download-button.component';
import { CommentButtonComponent } from './components/elements/comment-button/comment-button.component';
import { ShowMoreComponent } from './components/elements//show-more/show-more.component';
import { AvatarComponent } from './components/elements/avatar/avatar.component';
import { SingleDropdownComponent } from './components/elements/single-dropdown/single-dropdown.component';
import { MultiDropdownComponent } from './components/elements/multi-dropdown/multi-dropdown.component';
import { SalesDateComponent } from './components/elements/sales-date-filter/sales-date-filter.component';
import { MovingAverageComponent } from './components/elements/moving-average-dropdown/moving-average-dropdown.component';
import { DateRangePickerComponent } from './components/elements/date-range-picker/date-range-picker.component';
import { FilterPipe } from './helpers/pipe';
import { VsLyBrandComponent } from './components/charts/sku-deepdive/vs-ly-brand/vs-ly-brand.component';
import { CtgPkgTableComponent } from './components/pages/analytics-ctg-pkg-table/table/table.component';
import { AuthInterceptor } from './helpers/AuthInterceptor';
import { ChatbotComponent } from './components/pages/chatbot/chatbot.component';

// 1206690193900751: Removing the hover text
const removeSvgTitle = (str: string) => str.replace(/<title>.*?<\/title>/, "<title></title>")
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    AuthenticationComponent,
    LoginComponent,
    AnalyticsTable,
    AnalyticsCategoryPackageTable,
    IntageDashboardComponent,
    IntageVMDashboardComponent,
    IntageFlashComponent,
    IntageFlashVMComponent,
    ContactUsComponent,
    SummaryDashboardComponent,
    NsrTrendComponent,
    NsrDailyComponent,
    NsrMonthlyComponent,
    GPMonthlyComponent,
    SalePerformanceComponent,
    ScoreCardsComponent,
    ScoreCardsVMComponent,
    IntageValueShareTimelineVMComponent,
    IntageOtcValueVsLyComponent,
    IntageVMOtcValueVsLyComponent,
    IntageValueShareTimelineComponent,
    IntageShareVsLyComponent,
    IntageVMShareVsLyComponent,
    IntageSankeySummaryComponent,
    IntageVMSankeySummaryComponent,
    IntageOtcMarketSizeComponent,
    IntageOtcMarketShareByPackageComponent,
    IntageOtcMarketSizeVMCCBJIComponent,
    IntageOtcMarketShareByRegionComponent,
    IntageOtcMarketShareVMByRegionComponent,
    IntageOtcMarketShareVolumeCCBJIComponent,
    HQProgressComponent,
    // FPT-HoiLT2: [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development 
    SkuCustomerComponent,
    SkuComponent,
    SkuDeepdiveComponent,
    AreaDeepdiveComponent,
    CustomerComponent,
    ErrorSkuCustomerComponent,
    MaintainSkuCustomerComponent,
    UnauthorizedComponent,
    ScoreCardsFlashComponent,
    IntageValueShareSummaryFlashComponent,
    ProcessingComponent,
    DownloadButtonComponent,
    CommentButtonComponent,
    ChatbotComponent,
    ShowMoreComponent,
    AvatarComponent,
    SingleDropdownComponent,
    ProcessingComponent,
    MovingAverageComponent,
    HQLevel1Component,
    HQLevel2Component,
    ProgressTimelineComponent,
    DateRangePickerComponent,
    HQNationalLocalComponent,

    DistrictRouterProgressComponent,
    ProgressByDistrictComponent,
    ProgressByRouteComponent,
    DistrictTimelineComponent,
    ProgressRankingComponent,
    AreaSalesRankingComponent,

    FaceShareComponent,
    FaceShareByMarkerCategoryComponent,
    FaceShareByAccountComponent,
    FaceShareBySkuComponent,
    FaceShareByAccountBySkuComponent,
    PocComponent,
    PocByAccountComponent,
    PocByCategoryComponent,

    VsLyCategoryComponent,
    VsLyPackageComponent,
    VsLySKUComponent,
    VsLyTimelineComponent,
    VsLyAreaComponent,
    VsLyChannelComponent,
    VsLyRouteComponent,
    VsLyDealerComponent,
    VsLyAreaTimelineComponent,
    AddMoreFilter,
    AddMoreFilterHQ,
    PhotoReportFilter,
    CanvasComponent,

    FilterPipe,
    FilterRootsPipe,
    SortPipe,
    MultiDropdownComponent,
    SalesDateComponent,
    VsLyBrandComponent,

    CtgPkgTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgbTooltipModule,
    GoogleChartsModule,
    NgSelectModule,
    // FPT-HoiLT2: [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development 
    NgxPaginationModule,
    NgIconsModule.withIcons({
      IonCalendarClearOutline: removeSvgTitle(IonCalendarClearOutline),
      IonDownloadOutline: removeSvgTitle(IonDownloadOutline),
      IonGrid: removeSvgTitle(IonGrid),
      IonDocumentTextOutline: removeSvgTitle(IonDocumentTextOutline),
      IonDocumentOutline: removeSvgTitle(IonDocumentOutline),
      IonTimeOutline: removeSvgTitle(IonTimeOutline),
      IonChatbubbleEllipsesOutline: removeSvgTitle(IonChatbubbleEllipsesOutline),
      IonArrowRedoOutline: removeSvgTitle(IonArrowRedoOutline),
      IonEllipsisHorizontalOutline: removeSvgTitle(IonEllipsisHorizontalOutline),
      IonPaperPlaneSharp: removeSvgTitle(IonPaperPlaneSharp),
      IonTrashOutline: removeSvgTitle(IonTrashOutline),
      IonCreateOutline: removeSvgTitle(IonCreateOutline),
      IonCloseSharp: removeSvgTitle(IonCloseSharp),
    }),
    MsalModule,
    MentionModule,
    ScrollingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
