import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import { IntageHelper as IntageDashboardHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import lodashClonedeep from 'lodash.clonedeep';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
@Component({
  selector: 'intage_value-share-timeline-vm',
  templateUrl: './value-share-timeline-vm.component.html',
  styleUrls: ['./value-share-timeline-vm.component.scss']
})
export class IntageValueShareTimelineVMComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  otcWeeks: any;
  otcStartWeeks: number;  
  makerLogo: any;
  shareDisplayType: string;
  marketShareTimelineData: any;
  marketShareChart: ApexCharts;
  marketShareHaveSingleSerie: boolean;
  marketShareHaveData: boolean;
  marketShareSingleSerie: string;
  marketShareSingleSerieColor: string;
  marketShareMakers: any;
  shareTimelineSelectedCategory: any;
  shareTimelineSelectedRegion: any;
  populated: boolean;
  series: any;
  aggregateBy: any;
  monthSet: any;
  quarterSet: any;
  yearSet: any;
  categoriesFilter: any;
  districts: any;
  movingAverageWindowSizes: number[] = [4];
  items = [{}];
  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  
  ngOnChanges() {
      if(!this.populated){
        this.shareTimelineSelectedCategory = ['Total'];
        this.shareTimelineSelectedRegion = ['CCBJI'];
        this.shareDisplayType = 'value';
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [{maker:'All'}];
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked,
            show: true
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.categoriesFilter();
        this.districts = IntageHelper.district();
        this.otcStartWeeks = 52;
        this.aggregateBy = 'wtd';
        this.handleMarketShareTimeline();
      }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["shareTimelineSelectedCategory"] = [...this.shareTimelineSelectedCategory];
      window["shareTimelineSelectedRegion"] = [...this.shareTimelineSelectedRegion];
    } else {
      if (window["shareTimelineSelectedCategory"] != null) { this.shareTimelineSelectedCategory = window["shareTimelineSelectedCategory"]; }
      if (window["shareTimelineSelectedRegion"] != null) { this.shareTimelineSelectedRegion = window["shareTimelineSelectedRegion"]; }
    }
  }

  skipAllValue(makers: any): any {
    return makers.filter(e => e.maker != 'All' && e.show);
  }
     
  onRemoveFilterShareCategory($event) {
    if(this.shareTimelineSelectedCategory.length == 0){
      this.shareTimelineSelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLineCategoryChange()
    }
    if ($event.value === 'Total') {
      this.shareTimelineSelectedCategory = [];
      return;
    }
  }
  onAddFilterShareCategory($event) {
    if ($event === 'Total') {
      this.shareTimelineSelectedCategory = ['Total'];
      return;
    }
    if(this.shareTimelineSelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.shareTimelineSelectedCategory = ['Total'];
    }else{
      this.shareTimelineSelectedCategory = this.shareTimelineSelectedCategory.filter(e => e !== 'Total');;
    }
  }
  onRemoveFilterTimelineRegion($event) {
    if(this.shareTimelineSelectedRegion.length == 0){
      this.shareTimelineSelectedRegion = ['CCBJI'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLineRegionChange()
    }
    if ($event.value === 'CCBJI') {
      this.shareTimelineSelectedRegion = [];
      return;
    }
  }
  onAddFilterSTimelineRegion($event) {
    if ($event === 'CCBJI') {
      this.shareTimelineSelectedRegion = ['CCBJI'];
      return;
    }
    if(this.shareTimelineSelectedRegion.filter(x=> x != 'CCBJI').length == (this.districts.length-1)){
      this.shareTimelineSelectedRegion = ['CCBJI'];
    }else{
      this.shareTimelineSelectedRegion = this.shareTimelineSelectedRegion.filter(e => e !== 'CCBJI');;
    }
  }  
 
  async handleMarketShareTimeline(){
    try
    {
      this.otcWeeks = await IndexDbHelper.Get('IntageVmDashboardWeeks');
      this.otcWeeks = this.otcWeeks.filter(x => x <= this.selectedWeek);
      let startWeek = this.otcWeeks.length >= this.otcStartWeeks ? this.otcWeeks[this.otcStartWeeks - 1] : this.otcWeeks[this.otcWeeks.length - 1]; 
      this.otcWeeks.reverse();
      this.items = this.dropdownValueToNgSelectFormat(this.otcWeeks);
      let data = lodashClonedeep(this.intage.filter(x => x.week >= startWeek && x.week <= this.selectedWeek && x.tyvalue != "" && x.lyvalue != "" && x.tyunit != "" && x.lyunit != ""));

      data = data.filter(x => x.botter_sci == this.shareTimelineSelectedRegion[0]);
      if (!this.shareTimelineSelectedCategory.includes('Total'))
      {
        data = data.filter(x => x.category == this.shareTimelineSelectedCategory[0]);
      }

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly(data);
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly(data);          
          break;
        case 'ytd':
          this.handleIntageOTCYearly(data);
          break;
        default:
          this.handleIntageOTCWeekly(data);
          break;
      }
      let t= this;
      setTimeout(() => {
        t.ready.emit(true);
      });
    }
    catch(e){
    }
   
  }

  handleIntageOTCWeekly(data: any)
  {
    this.marketShareTimelineData = [];
    let weeks = [...new Set(data.map(x=> x.week))];
    data = IntageHelper.transformData(data);

    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      weeks.forEach(week => {
        let dataMakerWeek = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && x.week == week);
        let tyValueMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let tyValueMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.tyvalue, 0);

        let sharetyValueNumber = tyValueMaker / tyValueMakeretSize * 100;
        let sharetyValue = tyValueMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyValueNumber);

        let tyUnitMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.tyunit, 0);

        let sharetyUnitNumber = tyUnitMaker / tyUnitMakeretSize * 100;
        let sharetyUnit = tyUnitMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyUnitNumber);
  
        let marketShareTimeLineWeek = this.marketShareTimelineData.find(x => x.week == week);
        if (marketShareTimeLineWeek == null)
        {
          this.marketShareTimelineData.push({
            week: week,
            makers: [
              {
                maker: maker.maker,
                sharetyValue: sharetyValue,
                sharetyUnit: sharetyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineWeek.makers.push(
            {
              maker: maker.maker,
              sharetyValue: sharetyValue,
              sharetyUnit: sharetyUnit
            }
          )
        }
      })
    })

    this.marketShareTimelineData.reverse();
    weeks.reverse();
    this.renderMarketShareTimelineChart(weeks);
  }

  handleIntageOTCMonthly(data: any)
  {
    this.marketShareTimelineData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_month = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.monthSet = [...new Set(intage_month.map(x=> x.month))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.monthSet.forEach(month => {
        let intageMonth = intage_month.filter(x => x.month == month);

        let dataMakerMonth = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageMonth.some(m => m.week == x.week));
        let tyValueMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeMonth = data.filter(x => intageMonth.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let sharetyValueNumber = tyValueMaker / tyValueMakeretSize * 100;
        let sharetyValue = tyValueMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyValueNumber);

        let tyUnitMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.tyunit, 0);
        let sharetyUnitNumber = tyUnitMaker / tyUnitMakeretSize * 100;
        let sharetyUnit = tyUnitMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyUnitNumber);
  
        let marketShareTimeLineMonth = this.marketShareTimelineData.find(x => x.month == month);
        if (marketShareTimeLineMonth == null)
        {
          this.marketShareTimelineData.push({
            month: month,
            makers: [
              {
                maker: maker.maker,
                sharetyValue: sharetyValue,
                sharetyUnit: sharetyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineMonth.makers.push(
            {
              maker: maker.maker,
              sharetyValue: sharetyValue,
              sharetyUnit: sharetyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareTimelineChart(this.monthSet);
  }

  handleIntageOTCQuarterly(data: any)
  {
    this.marketShareTimelineData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_quarter = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.quarterSet = [...new Set(intage_quarter.map(x=> x.quarter))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.quarterSet.forEach(quarter => {
        let intageQuarter = intage_quarter.filter(x => x.quarter == quarter);

        let dataMakerQuarter = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageQuarter.some(m => m.week == x.week));
        let tyValueMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeQuarter = data.filter(x => intageQuarter.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let sharetyValueNumber = tyValueMaker / tyValueMakeretSize * 100;
        let sharetyValue = tyValueMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyValueNumber);

        let tyUnitMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.tyunit, 0);
        let sharetyUnitNumber = tyUnitMaker / tyUnitMakeretSize * 100;
        let sharetyUnit = tyUnitMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyUnitNumber);
  
        let marketShareTimeLineMonth = this.marketShareTimelineData.find(x => x.quarter == quarter);
        if (marketShareTimeLineMonth == null)
        {
          this.marketShareTimelineData.push({
            quarter: quarter,
            makers: [
              {
                maker: maker.maker,
                sharetyValue: sharetyValue,
                sharetyUnit: sharetyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineMonth.makers.push(
            {
              maker: maker.maker,
              sharetyValue: sharetyValue,
              sharetyUnit: sharetyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareTimelineChart(this.quarterSet);
  }

  handleIntageOTCYearly(data: any)
  {
    this.marketShareTimelineData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_year = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.yearSet = [...new Set(intage_year.map(x=> x.year))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.yearSet.forEach(year => {
        let intageYear = intage_year.filter(x => x.year == year);

        let dataMakerYear = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageYear.some(m => m.week == x.week));
        let tyValueMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeYear = data.filter(x => intageYear.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let sharetyValueNumber = tyValueMaker / tyValueMakeretSize * 100;
        let sharetyValue = tyValueMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyValueNumber);

        let tyUnitMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.tyunit, 0);
        let sharetyUnitNumber = tyUnitMaker / tyUnitMakeretSize * 100;
        let sharetyUnit = tyUnitMakeretSize == 0 ? NaN : IntageHelper.formatIntageNumber(sharetyUnitNumber);
  
        let marketShareTimeLineMonth = this.marketShareTimelineData.find(x => x.year == year);
        if (marketShareTimeLineMonth == null)
        {
          this.marketShareTimelineData.push({
            year: year,
            makers: [
              {
                maker: maker.maker,
                sharetyValue: sharetyValue,
                sharetyUnit: sharetyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineMonth.makers.push(
            {
              maker: maker.maker,
              sharetyValue: sharetyValue,
              sharetyUnit: sharetyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareTimelineChart(this.yearSet);
  }

  onValueShareAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareTimeline();
  }
  
  renderMarketShareTimelineChart(categories: any){
    if(this.marketShareChart != null){
      this.marketShareChart.destroy();
    }

    this.series = [];
    let colors = [];
    for(let i=0;i<this.marketShareMakers.length;i++){
      let maker = this.marketShareMakers[i];
      if(maker.checked){
        let serie = {
          name : maker.maker,
          data : []
        };
        for(let j=0;j<this.marketShareTimelineData.length;j++){
          let currentMaker = this.marketShareTimelineData[j].makers.find(x=> x.maker == maker.maker);
          if(currentMaker == null){
            serie.data.push("-");
            continue;
          }
          if (this.shareDisplayType == 'value') {
            let valueTemp = currentMaker.sharetyValue;
            serie.data.push(valueTemp);
          }
          else if (this.shareDisplayType == 'volume') {
            let valueTemp = currentMaker.sharetyUnit;
            serie.data.push(valueTemp);
          }          
        }
        
        this.series.push(serie);
        colors.push(IntageDashboardHelper.GetMakerColor(serie.name));
      }
    }

    this.marketShareHaveData = false;
    for(let i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.marketShareHaveData = true;
        break;
      }
    }
    this.marketShareHaveSingleSerie = false;
    if(this.marketShareHaveData && this.series.length == 1){
      this.marketShareHaveSingleSerie = true;
      this.marketShareSingleSerie = this.series[0].name;
      this.marketShareSingleSerieColor = colors[0];
    }

    const options = {
        chart: {
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
        },
        dataLabels: {
            enabled: this.marketShareHaveSingleSerie ? true : false,
            style:{
              colors: [
                function (w) {
                  if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                    return w.w.config.colors[w.seriesIndex];
                  } else {
                    return "#FF2626";
                  }
                },
              ],
              fontSize : "14px"
            }
        },
        stroke: {
            //width: [5, 5, 5],
            curve: 'smooth',
            //dashArray: [5, 5, 5]
        },
        colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: true
            }
        },
        markers: {
            size: this.marketShareHaveSingleSerie ? 0 : 5,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
          categories: categories
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        yaxis: {
          forceNiceScale: true,
          //min: -50
        }
      };        

    document.querySelector("#intage-vm-market-share-chart").innerHTML = '';
    this.marketShareChart = new ApexCharts(
        document.querySelector("#intage-vm-market-share-chart"),
        options
    );
    this.marketShareChart.render();    
  }

  public onShareDisplayTypeChange(type: string){
    this.shareDisplayType = type;
    this.handleMarketShareTimeline();
  }

  public onShareTimeLineCategoryChange(){
    if(this.shareTimelineSelectedCategory.length==0){
      this.shareTimelineSelectedCategory = ['Total'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareTimeLineRegionChange(){
    if(this.shareTimelineSelectedRegion.length==0){
      this.shareTimelineSelectedRegion = ['CCBJI'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareMakersCheckedChanged(maker: string){
    let currentMaker = this.marketShareMakers.find(x=> x.maker == maker);
    currentMaker.checked = !currentMaker.checked;
    this.handleMarketShareTimeline();
  }

  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleMarketShareTimeline();
  }

  public exportValueShareTimeline(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    let formattedWeek = this.selectedWeek.replace(/-/g, "");
    let fileName = ``;
    switch (this.aggregateBy) {
      case 'mtd':
        fileName = this.shareDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Monthly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Monthly_${formattedWeek}`; 
      break;
      case 'qtd':
        fileName = this.shareDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Quarterly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Quarterly_${formattedWeek}`; 
      break;
      case 'ytd':
        fileName = this.shareDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Yearly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Yearly_${formattedWeek}`; 
      break;
      default:
        fileName = this.shareDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Weekly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Weekly_${formattedWeek}`; 
      break;
    }

    let shareColumn = this.shareDisplayType == 'value' ? 'Share Value' : 'Share Volume';

    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];

      switch (this.aggregateBy) {
        case 'mtd':
          for (let i = 0; i < this.monthSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  month: this.monthSet[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,             
                  share: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Month", "Maker", "Display", "Category", "Region", shareColumn]
          });
          break;
        case 'qtd':
          for (let i = 0; i < this.quarterSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  quarter: this.quarterSet[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,             
                  share: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Quarter", "Maker", "Display", "Category", "Region", shareColumn]
          });       
          break;
        case 'ytd':
          for (let i = 0; i < this.yearSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  year: this.yearSet[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,              
                  share: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Year", "Maker", "Display", "Category", "Region", shareColumn]
          });
          break;
        default:
          let series = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });        
            for (let k = 0; k < series.length; k++)
            {
              for (let i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  maker: series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,             
                  share: IntageHelper.formatValueCsv(series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Week", "Maker", "Display", "Category", "Region", shareColumn]
          });
          break;
      }

    }
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-share-timeline');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  dropdownValueToNgSelectFormat(value) {
    let values = DropdownValueToNgSelectFormat(value);   
    if (values?.length && this?.otcStartWeeks) {
      if (!values.some(x => x.value == null || x.name == null))
      {
        if (values.length < this.otcStartWeeks) {
          setTimeout(() => {
            this.otcStartWeeks = values.length
          }, 500);
        }
      }       
    } 
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleMarketShareTimeline();   
  } 

  public formatCategoryName(categoryName: any)
  {
    return IntageHelper.formatCategoryName(categoryName);
  }
}
