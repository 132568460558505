<div class="chat-container">
    <div class="chat-header">
        <div class="welcome-message" *ngIf="messages.length===0">
            <h1>Welcome to CCBJI AI Chatbot!</h1>
            <p>This chatbot is powered by AI to provide you with instant responses to your queries.</p>
        </div>
        <div class="chat-body" *ngIf="messages.length>0">
            <div class="chat-messages" *ngFor="let message of messages">
                <div class="chat-message-content">
                    <div class="inner">
                        <div class="left">
                            <div class="circle" *ngIf="message.type==='user'">{{userLetter}}</div>
                            <div class="circle coke" *ngIf="message.type==='bot'"></div>
                        </div>
                        <div [ngClass]="{'right': true, 'hr': message !== messages[messages.length - 1]}" [innerHTML]="message.content"></div>
                    </div>
                </div>
            </div>
            <div class="loading" *ngIf="isLoading">
                <div class="text-center spinner-wrapper">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chat-footer">
        <div class="suggestions" *ngIf="messages.length===0">
            <div class="tiles">
                <div class="tile" (click)="sendMessage('What are the XXX this year?')">
                    <div class="tilte-content">
                        <h2>What are the</h2>
                        <p>XXX this year?</p>
                    </div>
                </div>
                <div class="tile" (click)="sendMessage('What are the YYY last year?')">
                    <div class="tilte-content">
                        <h2>What are the</h2>
                        <p>YYY last year?</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-input-container">
            <div class="top">
                <div class="box">
                    <textarea class="chat-input" placeholder="Type a message..." (keyup.enter)="sendMessage()"
                        [(ngModel)]="question"></textarea>
                    <div (click)="sendMessage()" [ngClass]="{'send-button': true, 'active': question.length > 0}">
                        <ng-icon name="IonPaperPlaneSharp" size="16px" color="white"></ng-icon>
                    </div>

                </div>
            </div>
            <div class="bottom">
                <p>You can input English or Japanese</p>
            </div>
        </div>
    </div>
</div>