import { Component, Renderer2, Inject, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import Swal from 'sweetalert2';
@Component({
  selector: 'sale-performance',
  templateUrl: './sale-performance.component.html',
  styleUrls: ['./sale-performance.component.scss']
})
export class SalePerformanceComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedDate: any;
  @Input() monthlyData: any;
  mtdSaleChannels: any;
  vmbuSaleChannels: any;
  executionSaleChannels: any;
  strategicSaleChannels: any;
  alcoholChannels: any;

  retailCompanyTotal: any;

  foodServiceCompanys : any;  
  channels: any;

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }

  ngOnChanges() {
    //Init data performance
    if(this.monthlyData != null && this.selectedDate != null)
    {
      this.handleMtdSaleChannels();
      this.handleVmbuSaleChannels();
      this.handleExecutionSaleChannels();
      this.handleStrategicSaleChannels();
      this.handleRetailCompanyTotal();
      this.handleFoodServiceCompany();
      this.handleChannels();
      let t=this;
      setTimeout(() => {
        t.ready.emit(true);
      });
    }
  }

  handleChannels()
  {
    this.channels = [...this.retailCompanyTotal, ...this.vmbuSaleChannels, ...this.executionSaleChannels, ...this.strategicSaleChannels, ...this.alcoholChannels];
  }

  handleMtdSaleChannels(){
    this.mtdSaleChannels = [];
    this.mtdSaleChannels.push(
      {
        name: 'Total incl. Alc',
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp: {
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
      },
      {
        name: 'Core Biz',
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    let channels = AnalyticsHelper.ChannelLv2();
    for(var i=0;i<channels.length;i++){
      let channel = channels[i];
      if(channel.channel.indexOf('Vending') == 0 && channel.level > 1){
        continue;
      }
      // if(channel.channel == 'Retail'){
      //   this.mtdSaleChannels.push({
      //     name: 'Retail/Food',
      //     imp: 0,
      //     bapc:{
      //       act: 0,
      //       ly: 0,
      //       bp: 0,
      //       vsLy: 0,
      //       vsBp: 0
      //     },
      //     nsr:{
      //       act: 0,
      //       ly: 0,
      //       bp: 0,
      //       vsLy: 0,
      //       vsBp: 0
      //     },
      //     nsrPerCase:{
      //       act: 0,
      //       ly: 0,
      //       bp: 0,
      //       vsLy: 0,
      //       vsBp: 0
      //     }
      //   });
      // }
      this.mtdSaleChannels.push({
        name: channel.channel,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });
    }
    this.mtdSaleChannels.push({
      name: 'Alcohol',
      imp: 0,
      bapc:{
        act: 0,
        ly: 0,
        bp: 0,
        vsLy: 0,
        vsBp: 0
      },
      nsr:{
        act: 0,
        ly: 0,
        bp: 0,
        vsLy: 0,
        vsBp: 0
      },
      nsrPerCase:{
        act: 0,
        ly: 0,
        bp: 0,
        vsLy: 0,
        vsBp: 0
      },
      gp:{
        act: 0,
        ly: 0,
        bp: 0,
        vsLy: 0,
        vsBp: 0
      },
      gpPerCase:{
        act: 0,
        ly: 0,
        bp: 0,
        vsLy: 0,
        vsBp: 0
      }
    });

    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.month != month){
        continue;
      }
      let channelCode = row.channelCode.substring(0,2);
      let matchChannels = channels.filter(x=> x.code.find(y=> y == channelCode));
      for(var j=0;j<matchChannels.length;j++){
        let channel = this.mtdSaleChannels.find(x=> x.name == matchChannels[j].channel);
        if(channel == null || row.regionCode == '7200'){
          continue;
        }
        channel.bapc.act += parseFloat(row.bapc_ty);
        channel.bapc.ly += parseFloat(row.bapc_ly);
        channel.bapc.bp += parseFloat(row.bapc_bp);
        channel.nsr.act += parseFloat(row.nsr_ty);
        channel.nsr.ly += parseFloat(row.nsr_ly);
        channel.nsr.bp += parseFloat(row.nsr_bp);
        channel.gp.act += parseFloat(row.gp_ty);
        channel.gp.ly += parseFloat(row.gp_ly);
        channel.gp.bp += parseFloat(row.gp_bp);
      }
      
      //Alcohol
      if(row.regionCode == '7200'){
        let alcoholChannel = this.mtdSaleChannels[this.mtdSaleChannels.length - 1];
        alcoholChannel.bapc.act += parseFloat(row.bapc_ty);
        alcoholChannel.bapc.ly += parseFloat(row.bapc_ly);
        alcoholChannel.bapc.bp += parseFloat(row.bapc_bp);
        alcoholChannel.nsr.act += parseFloat(row.nsr_ty);
        alcoholChannel.nsr.ly += parseFloat(row.nsr_ly);
        alcoholChannel.nsr.bp += parseFloat(row.nsr_bp);
        alcoholChannel.gp.act += parseFloat(row.gp_ty);
        alcoholChannel.gp.ly += parseFloat(row.gp_ly);
        alcoholChannel.gp.bp += parseFloat(row.gp_bp);
      }
      else
      {
        //Core biz
        let coreBizChannel = this.mtdSaleChannels[1];
        coreBizChannel.bapc.act += parseFloat(row.bapc_ty);
        coreBizChannel.bapc.ly += parseFloat(row.bapc_ly);
        coreBizChannel.bapc.bp += parseFloat(row.bapc_bp);
        coreBizChannel.nsr.act += parseFloat(row.nsr_ty);
        coreBizChannel.nsr.ly += parseFloat(row.nsr_ly);
        coreBizChannel.nsr.bp += parseFloat(row.nsr_bp);
        coreBizChannel.gp.act += parseFloat(row.gp_ty);
        coreBizChannel.gp.ly += parseFloat(row.gp_ly);
        coreBizChannel.gp.bp += parseFloat(row.gp_bp);
      }
      // if(channelCode === "31" || channelCode === "32"){
      //   var retailFoodChannel = this.mtdSaleChannels.find(x=> x.name == 'Retail/Food');
      //   retailFoodChannel.bapc.act += parseFloat(row.bapc_ty);
      //   retailFoodChannel.bapc.ly += parseFloat(row.bapc_ly);
      //   retailFoodChannel.bapc.bp += parseFloat(row.bapc_bp);
      //   retailFoodChannel.nsr.act += parseFloat(row.nsr_ty);
      //   retailFoodChannel.nsr.ly += parseFloat(row.nsr_ly);
      //   retailFoodChannel.nsr.bp += parseFloat(row.nsr_bp);
      // }
      this.mtdSaleChannels[0].bapc.act += parseFloat(row.bapc_ty);
      this.mtdSaleChannels[0].bapc.ly += parseFloat(row.bapc_ly);
      this.mtdSaleChannels[0].bapc.bp += parseFloat(row.bapc_bp);
      this.mtdSaleChannels[0].nsr.act += parseFloat(row.nsr_ty);
      this.mtdSaleChannels[0].nsr.ly += parseFloat(row.nsr_ly);
      this.mtdSaleChannels[0].nsr.bp += parseFloat(row.nsr_bp);
      this.mtdSaleChannels[0].gp.act += parseFloat(row.gp_ty);
      this.mtdSaleChannels[0].gp.ly += parseFloat(row.gp_ly);
      this.mtdSaleChannels[0].gp.bp += parseFloat(row.gp_bp);
    }
    
    for(var i=0;i<this.mtdSaleChannels.length;i++){
      let channel = this.mtdSaleChannels[i];
      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);

      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.mtdSaleChannels[0].nsr.act).toFixed(1));
      }

      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
  }


  handleVmbuSaleChannels(){
    this.vmbuSaleChannels = [];
    this.vmbuSaleChannels.push(
      {
        name: 'VMBU Total',
        backgroundTotal: true,
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    let channels = AnalyticsHelper.Branches();
    for(var i=0;i<channels.length;i++){
      let channel = channels[i];
      this.vmbuSaleChannels.push({
        name: channel.name,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });
    }

    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      // FPT-HoiLT2: [20230118]SENSE Issue mismatch QC
      if(row.channelCode == 411 || row.month != month || !channels.some((x) => x.districtCode.some(y => y == row.districtCode)) || !channels.some((x) => x.regionCode == row.regionCode)){
        continue;
      }
      
      let matchChannel = channels.find(x=> x.regionCode == row.regionCode && x.districtCode.some(y => y == row.districtCode));
      if(matchChannel != null){
        let channel = this.vmbuSaleChannels.find(x=> x.name == matchChannel.name);
        if(channel != null){
          // FPT-HoiLT2: [20230118]SENSE Issue mismatch QC
          if(!['132', '331', '332', '411'].includes(row.channelCode)){
            channel.bapc.act += parseFloat(row.bapc_ty);
            channel.bapc.ly += parseFloat(row.bapc_ly);
            channel.bapc.bp += parseFloat(row.bapc_bp);
            channel.nsr.act += parseFloat(row.nsr_ty);
            channel.nsr.ly += parseFloat(row.nsr_ly);
            channel.nsr.bp += parseFloat(row.nsr_bp);
            channel.gp.act += parseFloat(row.gp_ty);
            channel.gp.ly += parseFloat(row.gp_ly);
            channel.gp.bp += parseFloat(row.gp_bp);
          }
        }
      }
      
      if (row.regionCode != '9100')
      {
        this.vmbuSaleChannels[0].bapc.act += parseFloat(row.bapc_ty);
        this.vmbuSaleChannels[0].bapc.ly += parseFloat(row.bapc_ly);
        this.vmbuSaleChannels[0].bapc.bp += parseFloat(row.bapc_bp);
        this.vmbuSaleChannels[0].nsr.act += parseFloat(row.nsr_ty);
        this.vmbuSaleChannels[0].nsr.ly += parseFloat(row.nsr_ly);
        this.vmbuSaleChannels[0].nsr.bp += parseFloat(row.nsr_bp);
        this.vmbuSaleChannels[0].gp.act += parseFloat(row.gp_ty);
        this.vmbuSaleChannels[0].gp.ly += parseFloat(row.gp_ly);
        this.vmbuSaleChannels[0].gp.bp += parseFloat(row.gp_bp);
      }
    }
    
    for(var i=0;i<this.vmbuSaleChannels.length;i++){
      let channel = this.vmbuSaleChannels[i];
      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);
      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.vmbuSaleChannels[0].nsr.act).toFixed(1));
      }

      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
  }

  handleExecutionSaleChannels(){
    this.executionSaleChannels = [];
    this.executionSaleChannels.push(
      {
        name: 'National Sales Division Total',
        backgroundTotal: true,
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    let channels = AnalyticsHelper.Regions();
    for(var i=0;i<channels.length;i++){
      let channel = channels[i];
      this.executionSaleChannels.push({
        name: channel.name,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });
    }

    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.channelCode == 411 || row.month != month || !channels.some((x) => x.code == row.regionCode)){
        continue;
      }
      
      let matchChannel = channels.find(x=> x.code == row.regionCode);
      if(matchChannel != null){
        let channel = this.executionSaleChannels.find(x=> x.name == matchChannel.name);
        if(channel != null){
          channel.bapc.act += parseFloat(row.bapc_ty);
          channel.bapc.ly += parseFloat(row.bapc_ly);
          channel.bapc.bp += parseFloat(row.bapc_bp);
          channel.nsr.act += parseFloat(row.nsr_ty);
          channel.nsr.ly += parseFloat(row.nsr_ly);
          channel.nsr.bp += parseFloat(row.nsr_bp);
          channel.gp.act += parseFloat(row.gp_ty);
          channel.gp.ly += parseFloat(row.gp_ly);
          channel.gp.bp += parseFloat(row.gp_bp);
        }
      }

      this.executionSaleChannels[0].bapc.act += parseFloat(row.bapc_ty);
      this.executionSaleChannels[0].bapc.ly += parseFloat(row.bapc_ly);
      this.executionSaleChannels[0].bapc.bp += parseFloat(row.bapc_bp);
      this.executionSaleChannels[0].nsr.act += parseFloat(row.nsr_ty);
      this.executionSaleChannels[0].nsr.ly += parseFloat(row.nsr_ly);
      this.executionSaleChannels[0].nsr.bp += parseFloat(row.nsr_bp);
      this.executionSaleChannels[0].gp.act += parseFloat(row.gp_ty);
      this.executionSaleChannels[0].gp.ly += parseFloat(row.gp_ly);
      this.executionSaleChannels[0].gp.bp += parseFloat(row.gp_bp);
    }
    
    for(var i=0;i<this.executionSaleChannels.length;i++){
      let channel = this.executionSaleChannels[i];
      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);

      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.executionSaleChannels[0].nsr.act).toFixed(1));
      }

      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
  }

  handleStrategicSaleChannels(){
    this.strategicSaleChannels = [];
    this.alcoholChannels = [];
    this.strategicSaleChannels.push(
      {
        name: 'Customer Development Total',
        backgroundTotal: true,
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    this.alcoholChannels.push(
      {
        name: 'Alcohol Total',
        backgroundTotal: true,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    let channels = AnalyticsHelper.ChannelLv3();
    for(var i=0;i<channels.length;i++){
      let channel = channels[i];
      this.strategicSaleChannels.push({
        name: channel.channel,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });
    }
    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.month != month){
        continue;
      }

      let matchChannels = channels.filter(x=> x.code.find(y=> y == row.channelCode));
      for(var j=0;j<matchChannels.length;j++){
        let channel = this.strategicSaleChannels.find(x=> x.name == matchChannels[j].channel);
        if(row.channelCode == 411 || channel == null || row.regionCode == '7200' || row.regionCode == '9100'){
          continue;
        }
        channel.bapc.act += parseFloat(row.bapc_ty);
        channel.bapc.ly += parseFloat(row.bapc_ly);
        channel.bapc.bp += parseFloat(row.bapc_bp);
        channel.nsr.act += parseFloat(row.nsr_ty);
        channel.nsr.ly += parseFloat(row.nsr_ly);
        channel.nsr.bp += parseFloat(row.nsr_bp);
        channel.gp.act += parseFloat(row.gp_ty);
        channel.gp.ly += parseFloat(row.gp_ly);
        channel.gp.bp += parseFloat(row.gp_bp);
      }
      
      //Alcohol
      if(row.regionCode == '7200'){
        let alcoholChannel = this.alcoholChannels[0];
        alcoholChannel.bapc.act += parseFloat(row.bapc_ty);
        alcoholChannel.bapc.ly += parseFloat(row.bapc_ly);
        alcoholChannel.bapc.bp += parseFloat(row.bapc_bp);
        alcoholChannel.nsr.act += parseFloat(row.nsr_ty);
        alcoholChannel.nsr.ly += parseFloat(row.nsr_ly);
        alcoholChannel.nsr.bp += parseFloat(row.nsr_bp);
        alcoholChannel.gp.act += parseFloat(row.gp_ty);
        alcoholChannel.gp.ly += parseFloat(row.gp_ly);
        alcoholChannel.gp.bp += parseFloat(row.gp_bp);
      }

      // else
      // {
      //   //Core biz
      //   let coreBizChannel = this.mtdSaleChannels[1];
      //   coreBizChannel.bapc.act += parseFloat(row.bapc_ty);
      //   coreBizChannel.bapc.ly += parseFloat(row.bapc_ly);
      //   coreBizChannel.bapc.bp += parseFloat(row.bapc_bp);
      //   coreBizChannel.nsr.act += parseFloat(row.nsr_ty);
      //   coreBizChannel.nsr.ly += parseFloat(row.nsr_ly);
      //   coreBizChannel.nsr.bp += parseFloat(row.nsr_bp);
      // }
      
      if(matchChannels.length > 0){
        if (row.regionCode != '9100')
        {
          this.strategicSaleChannels[0].bapc.act += parseFloat(row.bapc_ty);
          this.strategicSaleChannels[0].bapc.ly += parseFloat(row.bapc_ly);
          this.strategicSaleChannels[0].bapc.bp += parseFloat(row.bapc_bp);
          this.strategicSaleChannels[0].nsr.act += parseFloat(row.nsr_ty);
          this.strategicSaleChannels[0].nsr.ly += parseFloat(row.nsr_ly);
          this.strategicSaleChannels[0].nsr.bp += parseFloat(row.nsr_bp);
          this.strategicSaleChannels[0].gp.act += parseFloat(row.gp_ty);
          this.strategicSaleChannels[0].gp.ly += parseFloat(row.gp_ly);
          this.strategicSaleChannels[0].gp.bp += parseFloat(row.gp_bp);
        }
      }
    }
    
    for(var i=0;i<this.strategicSaleChannels.length;i++){
      let channel = this.strategicSaleChannels[i];

      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);
      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.strategicSaleChannels[0].nsr.act).toFixed(1));
      }

      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
    for(var i=0;i<this.alcoholChannels.length;i++){
      let channel = this.alcoholChannels[i];

      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);
      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.strategicSaleChannels[0].nsr.act).toFixed(1));
      }else if(this.alcoholChannels.length == 1){
        channel.imp = 100;
      }
      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
  }

  handleRetailCompanyTotal(){
    this.retailCompanyTotal = [];
    this.retailCompanyTotal.push(
      {
        name: 'Total',
        backgroundTotal: true,
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });

    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");

    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.month != month ||   row.regionCode == '5000' || row.regionCode == '9100' ){
        continue;
      }
      
      this.retailCompanyTotal[0].bapc.act += parseFloat(row.bapc_ty);
      this.retailCompanyTotal[0].bapc.ly += parseFloat(row.bapc_ly);
      this.retailCompanyTotal[0].bapc.bp += parseFloat(row.bapc_bp);
      this.retailCompanyTotal[0].nsr.act += parseFloat(row.nsr_ty);
      this.retailCompanyTotal[0].nsr.ly += parseFloat(row.nsr_ly);
      this.retailCompanyTotal[0].nsr.bp += parseFloat(row.nsr_bp);
      this.retailCompanyTotal[0].gp.act += parseFloat(row.gp_ty);
      this.retailCompanyTotal[0].gp.ly += parseFloat(row.gp_ly);
      this.retailCompanyTotal[0].gp.bp += parseFloat(row.gp_bp);
    }
    
    let total = this.retailCompanyTotal[0];
    total.bapc.vsBp = AnalyticsHelper.percent(total.bapc.act, total.bapc.bp);
    total.bapc.vsLy = AnalyticsHelper.percent(total.bapc.act, total.bapc.ly);
    total.nsr.vsBp = AnalyticsHelper.percent(total.nsr.act, total.nsr.bp);
    total.nsr.vsLy = AnalyticsHelper.percent(total.nsr.act, total.nsr.ly);
    total.nsrPerCase = AnalyticsHelper.calcPerCase(total.nsr, total.bapc);
    total.nsrPerCase.act = Math.round(total.nsrPerCase.act/1000);
    total.nsrPerCase.measure = Math.round(total.nsrPerCase.measure/1000);
    total.gp.vsBp = AnalyticsHelper.percent(total.gp.act, total.gp.bp);
    total.gp.vsLy = AnalyticsHelper.percent(total.gp.act, total.gp.ly);
    total.gpPerCase = AnalyticsHelper.calcPerCase(total.gp, total.bapc);
    total.gpPerCase.act = Math.round(total.gpPerCase.act/1000);
    total.gpPerCase.measure = Math.round(total.gpPerCase.measure/1000);
  
    total.bapc.act = Math.round((total.bapc.act / 1000));
    total.bapc.ly = Math.round((total.bapc.ly / 1000));
    total.bapc.bp = Math.round((total.bapc.bp / 1000));
    total.nsr.act = Math.round((total.nsr.act / 1000000));
    total.nsr.ly = Math.round((total.nsr.ly / 1000000));
    total.nsr.bp = Math.round((total.nsr.bp / 1000000));
    total.gp.act = Math.round((total.gp.act / 1000000));
    total.gp.ly = Math.round((total.gp.ly / 1000000));
    total.gp.bp = Math.round((total.gp.bp / 1000000));
    this.retailCompanyTotal[0] = total;
  }

  handleFoodServiceCompany(){
    this.foodServiceCompanys = [];
    this.foodServiceCompanys.push(
      {
        name: 'FS Area Sales Total',
        backgroundTotal: true,
        imp: 100,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      }
    );
    let channels = AnalyticsHelper.FoodBranches();
    for(var i=0;i<channels.length;i++){
      let channel = channels[i];
      this.foodServiceCompanys.push({
        name: channel.name,
        imp: 0,
        bapc:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsr:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        nsrPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gp:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        },
        gpPerCase:{
          act: 0,
          ly: 0,
          bp: 0,
          vsLy: 0,
          vsBp: 0
        }
      });
    }
   
    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    for(var i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.channelCode == 411 || row.month != month || (!channels.some((x) => x.code == row.districtCode) && row.regionCode !== '5000') ){
        continue;
      }
      
      let matchChannel = channels.find(x=> x.code == row.districtCode);
      if(matchChannel != null){
        let channel = this.foodServiceCompanys.find(x=> x.name == matchChannel.name);

        if(channel != null ){
         // if(channel.name != 'Chushikoku' || !['223', '132', '331', '332'].includes(row.channelCode)){
            channel.bapc.act += parseFloat(row.bapc_ty);
            channel.bapc.ly += parseFloat(row.bapc_ly);
            channel.bapc.bp += parseFloat(row.bapc_bp);
            channel.nsr.act += parseFloat(row.nsr_ty);
            channel.nsr.ly += parseFloat(row.nsr_ly);
            channel.nsr.bp += parseFloat(row.nsr_bp);
            channel.gp.act += parseFloat(row.gp_ty);
            channel.gp.ly += parseFloat(row.gp_ly);
            channel.gp.bp += parseFloat(row.gp_bp);
          //}
        }
      }
      

      this.foodServiceCompanys[0].bapc.act += parseFloat(row.bapc_ty);
      this.foodServiceCompanys[0].bapc.ly += parseFloat(row.bapc_ly);
      this.foodServiceCompanys[0].bapc.bp += parseFloat(row.bapc_bp);
      this.foodServiceCompanys[0].nsr.act += parseFloat(row.nsr_ty);
      this.foodServiceCompanys[0].nsr.ly += parseFloat(row.nsr_ly);
      this.foodServiceCompanys[0].nsr.bp += parseFloat(row.nsr_bp);
      this.foodServiceCompanys[0].gp.act += parseFloat(row.gp_ty);
      this.foodServiceCompanys[0].gp.ly += parseFloat(row.gp_ly);
      this.foodServiceCompanys[0].gp.bp += parseFloat(row.gp_bp);
    }
    
    for(var i=0;i<this.foodServiceCompanys.length;i++){
      let channel = this.foodServiceCompanys[i];
      channel.bapc.vsBp = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.bp);
      channel.bapc.vsLy = AnalyticsHelper.percent(channel.bapc.act, channel.bapc.ly);
      channel.nsr.vsBp = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.bp);
      channel.nsr.vsLy = AnalyticsHelper.percent(channel.nsr.act, channel.nsr.ly);
      channel.nsrPerCase = AnalyticsHelper.calcPerCase(channel.nsr, channel.bapc);
      channel.nsrPerCase.act = Math.round(channel.nsrPerCase.act/1000);
      channel.nsrPerCase.measure = Math.round(channel.nsrPerCase.measure/1000);
      channel.gp.vsBp = AnalyticsHelper.percent(channel.gp.act, channel.gp.bp);
      channel.gp.vsLy = AnalyticsHelper.percent(channel.gp.act, channel.gp.ly);
      channel.gpPerCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
      channel.gpPerCase.act = Math.round(channel.gpPerCase.act/1000);
      channel.gpPerCase.measure = Math.round(channel.gpPerCase.measure/1000);
      if(i > 0){
        channel.imp = parseFloat((channel.nsr.act * 100 / 1000000 / this.foodServiceCompanys[0].nsr.act).toFixed(1));
      }

      channel.bapc.act = Math.round((channel.bapc.act / 1000));
      channel.bapc.ly = Math.round((channel.bapc.ly / 1000));
      channel.bapc.bp = Math.round((channel.bapc.bp / 1000));
      channel.nsr.act = Math.round((channel.nsr.act / 1000000));
      channel.nsr.ly = Math.round((channel.nsr.ly / 1000000));
      channel.nsr.bp = Math.round((channel.nsr.bp / 1000000));
      channel.gp.act = Math.round((channel.gp.act / 1000000));
      channel.gp.ly = Math.round((channel.gp.ly / 1000000));
      channel.gp.bp = Math.round((channel.gp.bp / 1000000));
    }
  }


  formatNumber(number: any) {
    return AnalyticsHelper.formatNumber(number);
  }
  /**
   * @author FPT-HoiLT2
   * @Desciption [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
   */
  formatPercent(number: any) {
    return AnalyticsHelper.formatPercent(number);
  }
  /**
   * @author FPT-HoiLT2
   * @Desciption [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
   */
  isChannelLv2(channelName: string){
    return AnalyticsHelper.ChannelLv2().some((obj) => obj.channel === channelName && obj.level === 2);
  }
  /**
   * @author FPT-HoiLT2
   * @Desciption [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
   */
  isVMBUSalesLv2(branchName: string){
    return AnalyticsHelper.Branches().some((obj) => obj.name === branchName);
  }
}
