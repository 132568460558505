import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CognitoIdentityProvider, AttributeType } from "@aws-sdk/client-cognito-identity-provider";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  { localStorageHelper } from 'src/app/helpers/localStorage';
import { SESSION_STORAGE_KEYS } from 'src/app/model/constants';

// import jwtDecode from "jwt-decode";
import { tokens } from 'src/app/model/constants';
declare var mixpanel;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private userAttributes: AttributeType[];
  public userName: string;
  public notifications = [];
  totalNotificationUnread = 0;
  paging = { currentPage: 0, start: 0, end: 0};
  constructor(private router: Router,
    private http: HttpClient) { }

  ngOnInit() {
    if (localStorage.getItem(tokens.accessToken) != null && localStorage.getItem("userinfo") != null) {
      this.userName = localStorage.getItem('username');
      let noti = localStorage.getItem('notification');
      if (noti === null)
      {
        this.getNotification();
      }
      else
      {
        let res = JSON.parse(noti);
        this.notifications = res.data?.senseNotifications.map((obj) => {
          const createdDate = new Date(obj.createdAt);
          return {
            id: obj.id,
            title: obj.title,
            description: obj.description,
            flag: res.data.senseNotificationCruds.find(
                (e) => e.senseNotification?.id === obj.id
            )?.flag || 0,
            date: createdDate ? [createdDate.getFullYear(), createdDate.getMonth() + 1, createdDate.getDate()].join('/') : ''
        }});
        this.totalNotificationUnread = this.notifications.filter((obj) => obj.flag === 0).length;
        this.paging = { currentPage: 0, start: 1, end: this.notifications.length > 10 ? 10 : this.notifications.length };
      }

    } 
    else {
      this.router.navigateByUrl('authentication/login');
    }
  }

  public handleLogout() {
    mixpanel.track('Signout');
    mixpanel.reset();
    this.clearStorage();
    location.href = 'authentication/login';
  }

  public getUser() {
    const cognitoidentityserviceprovider = new CognitoIdentityProvider(
      {
        region: environment.region,
        credentials: {
          accessKeyId: environment.credentials.accessKeyId,
          secretAccessKey: environment.credentials.secretAccessKey
        }
      });

    var params = {
      AccessToken: localStorage.getItem(tokens.accessToken)
    };

    cognitoidentityserviceprovider.getUser(params, (err, data) => {
      if (err) {
        if(err.stack.includes('TypeError: Failed to fetch')){
          return;
        }
        console.log("Failed to get user: " + err.stack);
        localStorageHelper.clear();
        this.router.navigateByUrl('authentication/login');
      }
      else {
        this.userAttributes = data.UserAttributes;
        this.userName = data.Username;
        localStorage.setItem('username', data.Username);
        //Get user group

      }
    });
  }

  public getUserAttribute(name: string) {
    if (this.userAttributes == null) {
      return null;
    }
    return this.userAttributes.find(x => x.Name == name).Value;
  }

  public getNotification() {
    let queryUrl = environment.hygraphGetNotification + "?cmsEnv=" + environment.cmsEnv + "&userName=" + this.userName;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            localStorage.setItem('notification', response);
            this.notifications = res.data?.senseNotifications.map((obj) => {
              const createdDate = new Date(obj.createdAt);
              return {
                id: obj.id,
                title: obj.title,
                description: obj.description,
                flag: res.data.senseNotificationCruds.find(
                    (e) => e.senseNotification?.id === obj.id
                )?.flag || 0,
                date: createdDate ? [createdDate.getFullYear(), createdDate.getMonth() + 1, createdDate.getDate()].join('/') : ''
            }});
            this.totalNotificationUnread = this.notifications.filter((obj) => obj.flag === 0).length;
            this.paging = { currentPage: 0, start: 1, end: this.notifications.length > 10 ? 10 : this.notifications.length };
        },
        error => 
        {
            console.log(error);                        
        }
    );
  }

  public async createReadNotificationByUser(id: string) {
    if(!id) {
      return;
    }
    const payload = [{
      user: this.userName,
      flag: 1,
      senseNotification: {
        connect: {
          id
        }
      }
    }]
    await this.insertNotificationReadByUser(payload);
  }

  public notificationClick(clickedItem: number) {
    const index = (this.paging.currentPage * 10) + clickedItem;
    this.notifications[index].state = !this.notifications[index].state;
  }

  public clickBellIcon() {
    this.paging = { currentPage: 0, start: 1, end: this.notifications.length > 10 ? 10 : this.notifications.length };
    this.notifications.forEach(e => e.state = false);
  }

  public async makeAllAsReadClick() {
    const payload = this.notifications
        .filter((obj) => obj.flag === 0)
        .map((obj) => ({
            user: this.userName,
            flag: 1,
            senseNotification: {
                connect: {
                    id: obj.id,
                },
            },
        }));
    if (payload.length === 0) {
        return;
    }
    await this.insertNotificationReadByUser(payload);
  }

  private async insertNotificationReadByUser(payload: { user: string; flag: number; senseNotification: { connect: { id: any; }; }; }[]) {
    let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'json'
    };
    const idList = payload.map(o => o.senseNotification.connect.id);
    this.notifications.filter((o) => o.flag !== 1 && idList.includes(o.id)).forEach((o) => o.flag = 1);
    this.totalNotificationUnread = this.totalNotificationUnread - idList.length;
    this.http.post<any>(environment.notificationApi, payload, httpOptions)
    .subscribe(
      response => {
          return response;
        },
        error => {
          console.log('error', error);
        }
      );
  }

  rangePage() {
    this.paging.start = this.paging.currentPage * 10 + 1;
    const endNumTemp = this.paging.currentPage * 10 + 10;
    this.paging.end = endNumTemp < this.notifications.length ? endNumTemp : this.notifications.length;
  }

  switchScrollTop(){
    const el = document.getElementById('notificationBody');
    el.scrollTo(0, 0);
  }

  previousPage() {
    if (this.paging.currentPage > 0) {
      this.paging.currentPage--;
      this.rangePage();
    }
  }

  nextPage() {
    if (this.paging.currentPage < this.notifications.length / 10 - 1) {
      this.paging.currentPage++;
      this.rangePage();
    }
  }

  gotoPage(i) {
    this.paging.currentPage = i-1;
    this.rangePage();
  }

  getTotalPage() {
    return  Math.ceil(this.notifications.length / 10);
  }

  private clearStorage() {
    localStorageHelper.clear();
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.REDIRECT_URL);
  }

  // TO-DO: Use Bootstrap hide show
  public onProcessImageHoverIn() {
    document.getElementsByClassName('process-detail')[0].classList.add('show');
  }

  public onProcessImageHoverOut() {
    document.getElementsByClassName('process-detail')[0].classList.remove('show');
  }

}
