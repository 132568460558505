import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { ApexChartsComponent } from './components/pages/apex-charts/apex-charts.component';
import { AnalyticsTable } from './components/pages/analytics-table/analytics-table.component';
import { AnalyticsCategoryPackageTable } from './components/pages/analytics-ctg-pkg-table/analytics-ctg-pkg-table.component';
import { IntageDashboardComponent } from './components/pages/intage/dashboard/intage_dashboard.component';
import { IntageVMDashboardComponent } from './components/pages/intage-vm/vm-dashboard/intage_vm_dashboard.component';
import { IntageFlashComponent } from './components/pages/intage/flash/intage_flash.component';
import { IntageFlashVMComponent } from './components/pages/intage-vm/flash/intage_flash_vm.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
// FPT-HoiLT2: [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development 
import { SkuCustomerComponent } from './components/pages/sku-customer/sku-customer.component';
import { ErrorSkuCustomerComponent } from './components/pages/sku-customer/error/error.component';
import { MaintainSkuCustomerComponent } from './components/pages/sku-customer/maintain/maintain.component';
import { BrowserUtils } from '@azure/msal-browser';
import { UnauthorizedComponent } from './components/pages/Unauthorized/unauthorize.component';
import { ProcessingComponent } from './components/pages/processing/processing.component';
import { HQProgressComponent } from './components/pages/hq-progress/hq_progress.component';
import { DistrictRouterProgressComponent } from './components/pages/district-route-progress/district_route_progress.component';
import { SkuDeepdiveComponent } from './components/pages/sku-deepdive/sku-deepdive.component';
import { AreaDeepdiveComponent } from './components/pages/area-deepdive/area-deepdive.component';
import { ProgressRankingComponent } from './components/pages/progress-ranking/progress-ranking.component';
import { FaceShareComponent } from './components/pages/faceshare/faceshare.component';
import { PocComponent } from './components/pages/poc/poc.component';
import { ChatbotComponent } from './components/pages/chatbot/chatbot.component';

const routes: Routes = [
  {path: 'daily-report', component: AnalyticsTable},
  {path: 'daily-ctg-pkg-report', component: AnalyticsCategoryPackageTable},
  {path: '', component: DashboardComponent},
  {path: 'dashboard', component: DashboardComponent},
  {
    path: 'authentication', component: AuthenticationComponent,
    children: [
      {path: 'login', data: { breadcrumb: 'Login' }, component: LoginComponent}
    ]
  },
  {path: 'contact-us', data: { breadcrumb: 'Contact Us' }, component: ContactUsComponent},
  {path: 'intage', data: { breadcrumb: 'Intage' }, component: IntageDashboardComponent},
  {path: 'intage-vm', data: { breadcrumb: 'Intage' }, component: IntageVMDashboardComponent},
  {path: 'intage-flash', data: { breadcrumb: 'Intage Flash' }, component: IntageFlashComponent},
  {path: 'intage-flash-vm', data: { breadcrumb: 'Intage Flash VM' }, component: IntageFlashVMComponent},
  // FPT-HoiLT2:  [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development 
  {path: 'sku-customer', component: SkuCustomerComponent},
  {path: 'sku-customer/error', component: ErrorSkuCustomerComponent},
  {path: 'sku-customer/maintain', component: MaintainSkuCustomerComponent},
  {path: 'sku-deepdive', component: SkuDeepdiveComponent},
  {path: 'area-deepdive', component: AreaDeepdiveComponent},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'processing', component: ProcessingComponent},

  {path: 'hq-progress', data: { breadcrumb: 'HQ Progress' }, component: HQProgressComponent},
  {path: 'district-route-progress' , data : {breadcrumb : 'District Route Progress'}, component : DistrictRouterProgressComponent},
  {path: 'progress-ranking' , data : {breadcrumb : 'Progress ranking'}, component : ProgressRankingComponent },
  {path: 'sovi', component: FaceShareComponent},
  {path: 'poc', component: PocComponent},
  {path: 'chatbot', component: ChatbotComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation:
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? 'enabledNonBlocking'
    : 'disabled', },)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
