<div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card mb-30">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3>NSR Monthly</h3>
                </div>

                <div class="filter-radio">
                    <div class="items">
                        <div class="item">
                            <input class="form-check-input" type="radio" name="vsRadios2" id="vsBP2" value="vsBP2"
                                [checked]="displayBP" (change)="onDisplayBPSelect()">
                            <label for="vsBP2">BP</label>
                        </div>
                        <div class="item">
                            <input class="form-check-input" type="radio" name="vsRadios2" id="vsNsrPerCase2" value="vsNsrPerCase2"
                                [checked]="displayNsrPerCase" (change)="onDisplayNsrPerCaseSelect()">
                            <label for="vsNsrPerCase2">NSR Per Case</label>
                        </div>
                    </div>
                </div>

                <div class="filter-section even-wrapper">
                    <div class="ng-section summary-section">
                        <b>Channel</b>
                        <ng-select #ngFilterMonthly class="filter-by-channel" [searchable]="false" [virtualScroll]="true" [(ngModel)]="monthlyTrendChannel" [multiple]="true" [closeOnSelect]="false"
                        (remove)="onRemoveFilterMonthly($event);onChangeCheckBox($event)"
                        (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                        (blur)="SaveOptionValueTemp(true)"
                        (add)="onAddFilterMonthly($event);onChangeCheckBox($event)"
                        [clearable]="monthlyTrendChannel.length > 1">
                            <ng-option *ngFor="let item of channels" [value]="item.name">
                                <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.name) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.name}}
                            </ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary" [disabled]="monthlyTrendChannel.length == 0" (click)="ngFilterMonthly.close();SaveOptionValueTemp(false);ChannelAggregate()">Aggregate</button>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items">
                                <ng-container *ngFor="let item of items; index as i">
                                    <ng-container *ngIf="i==0">
                                        {{item}}
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="items.length > 1">
                                    ...
                                </ng-container>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="ng-section summary-section">
                        <b>Region</b>
                        <ng-select #ngFilterSalesArea class="filter-by-region" [searchable]="true" [virtualScroll]="true"
                            [(ngModel)]="salesArea" [multiple]="true" [closeOnSelect]="false" (close)="onCloseSalesArea()"
                            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterSalesArea($event);onChangeCheckBox($event)"
                            (open)="setSalesArea();onChangeCheckBox($event)" (add)="onAddFilterSalesArea($event);onChangeCheckBox($event)">
                            <ng-option *ngFor="let item of salesAreaBinding" [value]="item">
                            <input type="checkbox" class="cb-multi-select"/> {{item.name}}
                            </ng-option>
                            <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary" [disabled]="salesArea.length == 0"
                                (click)="SalesAreaAggregate();ngFilterSalesArea.close();">Aggregate</button>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                <div class="text-over-dropdown">{{item.name}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                <span class="more-items">...</span>
                            </ng-container>
                            </ng-template>
                        </ng-select>
                    </div>                                  
                </div>
                <p></p>
                <div class="card-body">
                    <p *ngIf="displayNsrPerCase" class="mb-1" style="left: 12px;position: relative;">JPY</p>
                    <p *ngIf="!displayNsrPerCase" class="mb-1" style="left: 12px;position: relative;">MM JPY</p>
                    <div id="traffic-source-chart"></div>
                    <!-- <p class="mb-1 callout">This chart is using the most updated master data and transaction data from HANA</p> -->
                    <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                        <!-- <comment-button title1="NSR Monthly" title2="Display Vs - {{displayBP ? 'BP' : 'NSR Per Case'}}"
                        [chartId]="chartId" [chartRef]="nsrMonthlyTrendChart" [chatIdDependency]="[{selectedDate, state: {displayBP, displayNsrPerCase, monthlyTrendChannel, salesArea}}]"></comment-button> -->

                        <download-button>
                            <button ngbDropdownItem (click)="downloadPng()">Download PNG</button>
                            <button ngbDropdownItem (click)="downloadCSV()">Download CSV</button>
                        </download-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
