import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  messages: { content: string, type: string }[] = [];
  question: string = '';
  cortexEndpoint = environment.chatbotAPI;
  isLoading = false;
  userLetter = localStorage.getItem('username').toUpperCase().slice(0, 1);

  constructor(private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }

  ngOnInit() {
    if (!authorize()) {
      // TO-DO: Redirect to unauthorized page
      // this.router.navigateByUrl('unauthorized');
    }
  }

  sendMessage(question = this.question) {
    this.isLoading = true;
    const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
    const headers = { 'Authorization': `Bearer ${token}` }; // Set the Authorization header

    this.http.post(this.cortexEndpoint, { question }, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        this.messages.push({
          content: response.response.trim(),
          type: 'bot'
        });
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Error:', error);
        this.isLoading = false;
      }
    );

    if (question) {
      this.messages.push({
        content: question.trim(),
        type: 'user'
      });
      this.question = '';
    }
  }

}
