import { Component, OnInit } from '@angular/core';
import { tokens } from 'src/app/model/constants';
import { environment } from 'src/environments/environment';
import { SESSION_STORAGE_KEYS } from './../../../model/constants';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.checkuser();
  }

  public checkuser() {
    const token    = localStorage.getItem(tokens.accessToken);
    const userinfo = localStorage.getItem('userinfo');

    if (token == null || userinfo == null) {
      setInterval(this.checkuser, 200);
    } else {
      const userinfo_parsed = JSON.parse(userinfo);
      const customerData    = userinfo_parsed[environment.customer]?.[0]?.senseGroup || {};

      const redirectPage = customerData.redirectpage?.url;
      const pages        = customerData.pages || [];

      const previousPath = sessionStorage.getItem(SESSION_STORAGE_KEYS.REDIRECT_URL);
      const validPage    = pages.find((page: {url: string}) => page.url === previousPath);
      const targetPage   = validPage?.url || redirectPage || '/dashboard';

      location.href = targetPage;
    }
  }

}
