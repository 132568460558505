import { Component, Renderer2, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from '../intage_helper';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { Router } from '@angular/router';
import { DropdownValueToNgSelectFormat2 } from 'src/app/helpers/dropdown';
import { getSelectedWeekFromUrl } from 'src/app/helpers/queryString';
import { UserService } from 'src/app/services/user.services';
@Component({
  selector: 'app-intage_flash_vm',
  templateUrl: './intage_flash_vm.component.html',
  styleUrls: ['./intage_flash_vm.component.scss']
})
export class IntageFlashVMComponent implements OnInit {
  isChangeWeekReady: boolean;
  intageData: any;
  isOtcMarketSizeReady: boolean;
  isMarketShareByRegionReady: boolean;
  isMarketShareByRegionYTDReady: boolean;
  weeks: any;
  selectedWeek: string;
  categoryColors: any;
  channelOrder: any;
  intageMonthData: any;
  users: any;
  constructor(private router: Router,public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private userservice: UserService) { }

  async ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
      this.userservice.GetUsers(location.pathname).then((res) => {
        this.users = res;
      });
      this.categoryColors = IntageHelper.CategoryColors();
      this.channelOrder = IntageHelper.ChannelOrders();
      this.weeks = await IndexDbHelper.Get('IntageVmWeeks');
      IntageHelper.GetIntageVMWeekData(this.http).then(async (weeks) => {
        if (this.weeks == null || this.weeks.length != weeks.length)
        {
          this.weeks = [...new Set(weeks.map(x => x.week))];
          IntageHelper.GetIntageVMData(this.http).then((intage) => {      
            IndexDbHelper.Set('IntageVmWeeks', this.weeks);
            let weekList = this.weeks.map(x => "'" + x + "'");
            IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
              if(this.intageMonthData == null){
                this.intageMonthData = intageMonthData;
              }
              IndexDbHelper.Set('IntageVMMonthData', intageMonthData);
            });
            if(this.selectedWeek == null || this.selectedWeek == ''){
              this.selectedWeek = this.weeks[0] as string;
            }
            this.intageData = intage;
            IndexDbHelper.Set('IntageVmData', intage);
          });
        }
        else
        {
          if(this.selectedWeek == null || this.selectedWeek == ''){
            this.selectedWeek = this.weeks[0] as string;
          }
  
          let weekList = this.weeks.map(x => "'" + x + "'");
          IntageHelper.GetIntageMonthFromWeek(this.http, weekList.toString()).then((intageMonthData) => {
            this.intageMonthData = intageMonthData;
            IndexDbHelper.Set('IntageVMMonthData', intageMonthData);
          });
          
          this.intageData = await IndexDbHelper.Get('IntageVmData');
          if (this.intageData == null)
          {
            IntageHelper.GetIntageVMData(this.http).then((intage) => {
              this.intageData = intage;
              IndexDbHelper.Set('IntageVmData', intage);
            })
          }
        }
      })

      const selectedWeek = getSelectedWeekFromUrl(this.router);
      if (selectedWeek) {
        this.selectedWeek = selectedWeek;
        this.onSelectedWeekChanged(this.selectedWeek);
      }

    }
  }

  
  public onSelectedWeekChanged(selectedWeek: string){
    this.isChangeWeekReady = false;
    this.selectedWeek = selectedWeek;
    this.isOtcMarketSizeReady = false;
    this.isMarketShareByRegionReady = false;
  }
  public otcMarketSizeReady(ready: boolean){
    this.isOtcMarketSizeReady = ready;
  }
  public marketShareByRegionReady(ready: boolean){
    this.isMarketShareByRegionReady = ready;
    this.isChangeWeekReady = ready;
  }
 
  @ViewChild('pdf_print_area_intage_flash_vm_full', { static: false }) pdf_print_area_intage_flash_vm_full: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_intage_flash_vm_full.nativeElement)
  }

  dropdownValueToNgSelectFormat(value) {
    return DropdownValueToNgSelectFormat2(value)
  }

}
