<div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card mb-30">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3>Sales Performance (MTD)</h3>
                </div>
                <div class="card-body">
                    <div id="deep-dive-data">
                        <ng-container *ngIf="mtdSaleChannels != null">
                            <div class="table-wrapper">
                                <table class="w-full rounded shadow relative">
                                    <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
                                        <tr class="font-sans">
                                            <th rowspan="2" colspan="3" class="text-base p-2 top-0 left-0 font-bold whitespace-nowrap border-t border-gray-500 border-l">{{selectedDate.year}}/{{selectedDate.month}}/{{selectedDate.day}}&nbsp;</th>
                                        </tr>
                                        <tr class="font-sans">
                                            <th colspan="25" class="border-l border-gray-500 bg-red-100 text-lg top-0 left-0 font-bold border-t border-b border-r text-center">MTD</th>
                                        </tr>
                                        <tr class="text-xs font-normal text-center border-b border-gray-500">
                                            <th rowspan="2" colspan="2" class="border-t border-gray-500 border-l">By channel</th>
                                            <th rowspan="2" class="border-l border-t border-gray-500">NSR Imp. %</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">BAPC (K case)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">NSR (MM JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="5">NSR per case (JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">GP (MM JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="5">GP per case (JPY)</th>
                                        </tr>
                                        <tr class="text-xs leading-none tracking-tight font-normal border-b border-gray-500" >
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <th class="border-l border-gray-500 whitespace-  pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="2">% vs LY</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-xs">
                                        <td class="sticky px-3 text-xxs border-l border-gray-500 division" [attr.rowspan]="mtdSaleChannels.length + 1">CCBJI Sales</td>
                                        <ng-container *ngFor="let channel of mtdSaleChannels; index as i">
                                            <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <tr class="{{i > 1 ? '' : 'font-bold text-black'}} {{i === 0 ? 'background-total' : ''}}">
                                                <td class="shadow sticky _whitespace-nowrap pl-3 text-xxs set-width">
                                                    <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                    <span class="shadow-title {{i === 0 ? 'background-total' : ''}}" style="background: none;">{{isChannelLv2(channel.name) ? '　'+ channel.name : channel.name}}</span> <span class="shadow-column" style="background: none;"></span>
                                                </td>
                                                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1">{{formatPercent(channel.imp)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.bapc.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.bapc.vsBp > 0 ? 100 : (100 + channel.bapc.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.bapc.vsBp >= 0 ? channel.bapc.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.bapc.vsLy > 0 ? 100 : (100 + channel.bapc.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.bapc.vsLy >= 0 ? channel.bapc.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsr.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.nsr.vsBp > 0 ? 100 : (100 + channel.nsr.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsBp >= 0 ? channel.nsr.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.nsr.vsLy > 0 ? 100 : (100 + channel.nsr.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsLy >= 0 ? channel.nsr.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsrPerCase.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.nsrPerCase.vsBp > 0 ? 100 : (100 + channel.nsrPerCase.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsBp >= 0 ? channel.nsrPerCase.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsrPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.nsrPerCase.vsLy > 0 ? 100 : (100 + channel.nsrPerCase.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsLy >= 0 ? channel.nsrPerCase.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.nsrPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsLy)}}</td>





                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gp.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.gp.vsBp > 0 ? 100 : (100 + channel.gp.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsBp >= 0 ? channel.gp.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.gp.vsLy > 0 ? 100 : (100 + channel.gp.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsLy >= 0 ? channel.gp.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gpPerCase.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.gpPerCase.vsBp > 0 ? 100 : (100 + channel.gpPerCase.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsBp >= 0 ? channel.gpPerCase.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gpPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{i === 0 ? 'background-total' : ''}} red-bar" [style.width]="(channel.gpPerCase.vsLy > 0 ? 100 : (100 + channel.gpPerCase.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{i === 0 ? 'background-total' : ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsLy >= 0 ? channel.gpPerCase.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.gpPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsLy)}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>     
                        
                        <ng-container *ngIf="vmbuSaleChannels != null">
                            <div class="table-wrapper">
                                <table class="w-full rounded shadow mt-12 relative">
                                    <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
                                        <tr class="font-sans">
                                            <th rowspan="2" colspan="3" class="text-base p-2 top-0 left-0 font-bold whitespace-nowrap border-t border-gray-500 border-l">&nbsp;</th>
                                        </tr>
                                        <tr class="font-sans">
                                            <th colspan="25" class="border-l border-gray-500 bg-red-100 text-lg top-0 left-0 font-bold border-t border-b border-r text-center">MTD</th>
                                        </tr>
                                        <tr class="text-xs font-normal text-center border-b border-gray-500">
                                            <th rowspan="2" colspan="2" class="border-t border-gray-500 border-l">By Headquarter/Division</th>
                                            <th rowspan="2" class="border-l border-t border-gray-500">NSR Imp. %</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">BAPC (K case)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">NSR (MM JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="5">NSR per case (JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="5">GP (MM JPY)</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="5">GP per case (JPY)</th>
                                        </tr>
                                        <tr class="text-xs leading-none tracking-tight font-normal border-b border-gray-500" >
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs LY</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center">Act</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 text-center" colspan="2">% vs BP</th>
                                            <th class="border-l border-gray-500 whitespace-nowrap pr-2 pb-1 border-r text-center" colspan="2">% vs LY</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-xs">
                        
                                        <td class="sticky px-3 text-xxs border-l border-gray-500 division" 
                                        [attr.rowspan]="channels.length + 1">Retail Company</td>

                                        <ng-container *ngFor="let channel of channels; index as i">
                                            <!-- <tr class="{{i > 0 ? (i == channels.length - 1 ? 'border-b border-gray-500' : '') : 'font-bold text-black background-total'}}"> -->
                                            <tr [ngClass]="{
                                                'border-b border-gray-500': i == channels.length - 1,
                                                'font-bold text-black background-total': channel.backgroundTotal}">
                                                <td class="shadow sticky _whitespace-nowrap pl-3 text-xxs" style="left: 95px; min-width: 160px;">
                                                    <span class="shadow-title" >{{channel.name}}</span>
                                                </td>
                                                <td class="text-right px-2 py-1">{{formatPercent(channel.imp)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.bapc.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.bapc.vsBp > 0 ? 100 : (100 + channel.bapc.vsBp)) + '%'"></div></div>
                                                        <div class="positive-bar {{channel.backgroundTotal ? 'background-total': ''}}"><div class="bar-background green-bar" [style.width]="(channel.bapc.vsBp >= 0 ? channel.bapc.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.bapc.vsLy > 0 ? 100 : (100 + channel.bapc.vsLy)) + '%'"></div></div>
                                                        <div class="positive-bar {{channel.backgroundTotal ? 'background-total': ''}}"><div class="bar-background green-bar" [style.width]="(channel.bapc.vsLy >= 0 ? channel.bapc.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsr.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsr.vsBp > 0 ? 100 : (100 + channel.nsr.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsBp >= 0 ? channel.nsr.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsr.vsLy > 0 ? 100 : (100 + channel.nsr.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsLy >= 0 ? channel.nsr.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsrPerCase.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsrPerCase.vsBp > 0 ? 100 : (100 + channel.nsrPerCase.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsBp >= 0 ? channel.nsrPerCase.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsrPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsrPerCase.vsLy > 0 ? 100 : (100 + channel.nsrPerCase.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsLy >= 0 ? channel.nsrPerCase.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.nsrPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsLy)}}</td>

                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gp.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gp.vsBp > 0 ? 100 : (100 + channel.gp.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsBp >= 0 ? channel.gp.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gp.vsLy > 0 ? 100 : (100 + channel.gp.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsLy >= 0 ? channel.gp.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsLy)}}</td>
                                                <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gpPerCase.act)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gpPerCase.vsBp > 0 ? 100 : (100 + channel.gpPerCase.vsBp)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsBp >= 0 ? channel.gpPerCase.vsBp : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gpPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsBp)}}</td>
                                                <td class="border-l border-gray-500 chart">
                                                    <div>
                                                        <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gpPerCase.vsLy > 0 ? 100 : (100 + channel.gpPerCase.vsLy)) + '%'"></div></div>
                                                        <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsLy >= 0 ? channel.gpPerCase.vsLy : 0) + '%'"></div></div>
                                                    </div>
                                                </td>
                                                <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                                <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.gpPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsLy)}}</td>
                                            </tr>
                                        </ng-container>
                                    
                                    <tr class="border-new-group">
                                        <td class="sticky px-3 text-xxs border-l border-gray-500 division" [attr.rowspan]="foodServiceCompanys.length + 1">Food Service Company</td>
                                    </tr>
                                    <ng-container *ngFor="let channel of foodServiceCompanys; index as i">
                                        <tr [ngClass]="{
                                            'border-b border-gray-500': i == foodServiceCompanys.length - 1,
                                            'font-bold text-black background-total': channel.backgroundTotal}">
                                            <td class="shadow sticky _whitespace-nowrap pl-3 text-xxs set-width">
                                                <span class="shadow-title">{{channel.name}}</span>
                                            </td>
                                            <td class="text-right px-2 py-1">{{formatPercent(channel.imp)}}</td>
                                            <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.bapc.act)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.bapc.vsBp > 0 ? 100 : (100 + channel.bapc.vsBp)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.bapc.vsBp >= 0 ? channel.bapc.vsBp : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsBp)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.bapc.vsLy > 0 ? 100 : (100 + channel.bapc.vsLy)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.bapc.vsLy >= 0 ? channel.bapc.vsLy : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.bapc.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.bapc.vsLy)}}</td>
                                            <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsr.act)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsr.vsBp > 0 ? 100 : (100 + channel.nsr.vsBp)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsBp >= 0 ? channel.nsr.vsBp : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsBp)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsr.vsLy > 0 ? 100 : (100 + channel.nsr.vsLy)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsr.vsLy >= 0 ? channel.nsr.vsLy : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsr.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsr.vsLy)}}</td>
                                            <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.nsrPerCase.act)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsrPerCase.vsBp > 0 ? 100 : (100 + channel.nsrPerCase.vsBp)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsBp >= 0 ? channel.nsrPerCase.vsBp : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.nsrPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsBp)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.nsrPerCase.vsLy > 0 ? 100 : (100 + channel.nsrPerCase.vsLy)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.nsrPerCase.vsLy >= 0 ? channel.nsrPerCase.vsLy : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.nsrPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.nsrPerCase.vsLy)}}</td>

                                            <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gp.act)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gp.vsBp > 0 ? 100 : (100 + channel.gp.vsBp)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsBp >= 0 ? channel.gp.vsBp : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsBp)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gp.vsLy > 0 ? 100 : (100 + channel.gp.vsLy)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gp.vsLy >= 0 ? channel.gp.vsLy : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gp.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gp.vsLy)}}</td>
                                            <td class="text-right px-2 py-1 border-l border-gray-500">{{formatNumber(channel.gpPerCase.act)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gpPerCase.vsBp > 0 ? 100 : (100 + channel.gpPerCase.vsBp)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsBp >= 0 ? channel.gpPerCase.vsBp : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 {{channel.gpPerCase.vsBp > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsBp)}}</td>
                                            <td class="border-l border-gray-500 chart">
                                                <div>
                                                    <div class="negative-bar"><div class="bar-background {{channel.backgroundTotal ? 'background-total': ''}} red-bar" [style.width]="(channel.gpPerCase.vsLy > 0 ? 100 : (100 + channel.gpPerCase.vsLy)) + '%'"></div></div>
                                                    <div class=" positive-bar {{channel.backgroundTotal ? 'background-total': ''}} "><div class="bar-background green-bar" [style.width]="(channel.gpPerCase.vsLy >= 0 ? channel.gpPerCase.vsLy : 0) + '%'"></div></div>
                                                </div>
                                            </td>
                                            <!-- // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                                            <td class="text-right px-2 py-1 border-l border-gray-500 border-r {{channel.gpPerCase.vsLy > 0 ? '' : 'text-red-500'}}">{{formatPercent(channel.gpPerCase.vsLy)}}</td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </div>

                        </ng-container> 
                    </div>

                    <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                        <!-- <comment-button title1="Sales Performance (MTD)"
                        chartId="sales-performance-mtd" [chatIdDependency]="[{selectedDate}]"></comment-button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>